(function() {
  var config = angular.module('priorConfig');

  config.constant('defaultLanguageTranslations', {
    'ALERT_MODAL': {
      'ERROR_TITLE': '¡Uy, perdona!',
      'OK_BUTTON_LABEL': 'Vale',
      'SUCCESS_TITLE': ''
    },
    'CARD_MODAL': {
      'CCV_PLACEHOLDER': 'CCV (3 o 4 dígitos)',
      'EXPIRATION_DATE_PLACEHOLDER': 'Fecha de caducidad (MM/AA)',
      'MESSAGE': 'Introduce los datos de tu tarjeta de pago',
      'NAME_PLACEHOLDER': 'Nombre',
      'NUMBER_PLACEHOLDER': 'Número de la tarjeta',
      'TITLE': 'Datos de pago'
    },
    'COMPOSITE_MENU_ITEM_MODAL': {
      'COMPOSITE_RULE_ERROR_MESSAGE': '{exactRule, select, false{de {minCount} a {maxCount} cosas de {sectionName}} other{{minCount, plural, one{# cosa} other{# cosas}} de {sectionName}}}',
      'OR_LABEL': 'o',
      'PICK_LABEL': 'Elige'
    },
    'EDITABLE_COMPOSITE_MENU_ITEM_MODAL': {
      'INVALID_SELECTION_MODAL_TITLE': '¡Uy, algo falla!',
      'INVALID_SELECTION_MODAL_BUTTON': '@:CARD_PAGE.ERROR_MODAL_BUTTON_LABEL',
      'SAVE_BUTTON': 'Guardar',
      'TITLE': 'Configura'
    },
    'EDITABLE_COMPOSITE_MENU_ITEM_MODEL': {
      'INVALID_SELECTION_MESSAGE': '{emptyPrev, select, false{{prev}<br /> o <br />{current}} other{{current}}}'
    },
    'GENERIC_MENU_ITEM_MODAL': {
      'OPTIONS_LABEL': 'Opciones',
      'PICK_AN_OPTION_MESSAGE': 'Elige una opción',
      'SAVE_BUTTON': '@:EDITABLE_COMPOSITE_MENU_ITEM_MODAL.SAVE_BUTTON',
      'TITLE': '@:EDITABLE_COMPOSITE_MENU_ITEM_MODAL.TITLE'
    },
    'LOGIN_MODAL': {
      'ACCEPT_TOS_MESSAGE': 'Acepto las condiciones de uso',
      'EMAIL_PLACEHOLDER': 'Email',
      'EMAIL_SUGGESTION': '¿Quisiste decir ',
      'FACEBOOK_LOGIN_ERROR_MODAL_MISSING_EMAIL_PERMISSION_MESSAGE': 'We need your email address to identify your account. Please allow access to your email address from Facebook or, if you prefer it, create an account with a different email.',
      'GO_LOGIN_BUTTON': 'Entrar a mi cuenta',
      'GO_REGISTER_BUTTON': 'Crear nueva cuenta',
      'LOGIN_BUTTON': 'Entrar',
      'LOGIN_OR_REGISTER_MESSAGE': 'Entra a tu cuenta o crea una nueva.',
      'LOGIN_WITH_FACEBOOK_LABEL': 'Entrar con Facebook',
      'OR_LOGIN_WITH_EMAIL_LABEL': 'o si prefieres con tu email y contraseña',
      'OR_REGISTER_WITH_EMAIL_LABEL': 'o si prefieres con tu email y contraseña',
      'PASSWORD_PLACEHOLDER': 'Contraseña',
      'REGISTER_BUTTON': 'Crear cuenta',
      'REGISTER_WITH_FACEBOOK_LABEL': 'Crear cuenta con Facebook',
      'RESET_PASSWORD_BUTTON': 'Recuperar contraseña',
      'RESET_PASSWORD_LABEL': 'Recuperar contraseña',
      'RESET_PASSWORD_LINK_SENT': 'Te hemos enviado un enlace para recuperar tu contraseña al correo indicado!',
      'RESET_PASSWORD_MODAL_TITLE': 'Recuperar contraseña',
      'RETURN_TO_LOGIN_BUTTON': 'Volver',
      'TERMS_NOT_ACCEPTED_ERROR_MESSAGE': 'Necesitas aceptar las condiciones de uso para poder crear una cuenta',
      'TITLE': 'Entra o Registrate',
      'UNKNOWN_ERROR': 'Ha habido un error. Por favor intentalo de nuevo!',
      'USER_NOT_FOUND_ERROR': 'No se ha encontrado un usuario con ese correo!'
    },
    'MENU_PAGE': {
      'CATEGORIES_LABEL': 'Categorías',
      'KITCHEN_CLOSED_MESSAGE': 'Nuestras cocinas están cerradas. Puedes volver a intentarlo {openToday, select, false{mañana} other{a las {nextStartTime}}}.',
      'REVIEW_ORDER_BUTTON': 'Revisar pedido',
      'SEND_ORDER_BUTTON_END': 'enviarlo',
      'SEND_ORDER_BUTTON_START': 'En seguida puedes detallar y confirmar tu pedido antes de'
    },
    'NAVBAR': {
      'RESTAURANT_CLOSED_LABEL': 'Cerrado'
    },
    'ORDERED_PAGE': {
      'BUSY_REJECT_REASON': 'Lo sentimos, pero estamos ocupados. Vuelve a intentarlo en unos minutos.',
      'GENERIC_REJECT_REASON': 'Lo sentimos pero no hemos podido realizar tu pedido. Vuelve a intentarlo en algunos minutos.',
      'GO_TO_HOME_PAGE_LABEL': 'Volver al inicio',
      'MISSING_ITEM_REJECT_REASON': 'Lo sentimos, pero no tenemos los ingredientes necesarios para tu pedido. ¿Quieres pedir otra cosa?',
      'ORDER_ACCEPTED_DELIVERY_MESSAGE': 'Nuestro repartidor te lo entregará a esa hora.',
      'ORDER_ACCEPTED_LABEL': 'Pedido confirmado',
      'ORDER_ACCEPTED_MESSAGE': 'Lo tendrás el <span>{{date}}</span> a las <span>{{time}}</span>',
      'ORDER_AGAIN_LABEL': 'Volver a pedir',
      'ORDER_LABEL': 'Pedido',
      'ORDER_NOT_CONFIRMED_EXTRA_MESSAGE': 'Tu pedido ya se está tramitando, si tienes alguna duda puedes llamarnos al {{phoneNumber}}',
      'ORDER_NOT_CONFIRMED_MESSAGE': 'Pedido enviado al restaurante. En breves momentos te confirmaremos la hora.',
      'ORDER_REJECTED_LABEL': 'Pedido denegado',
      'ORDER_SENT_LABEL': 'Pedido enviado'
    },
    'ORDER_REVIEW_MODAL': {
      'EDIT_ORDER': 'Editar pedido',
      'ORDER_TOTAL': 'Total: {{ total | priorCurrency }}',
      'PAYMENT_DETAILS_CASH': 'Pagarás tu pedido en tienda',
      'PAYMENT_DETAILS_RAPPI_PAY': 'Pagarás tu pedido con RappiPay',
      'PAYMENT_DETAILS_REDIRECT': 'En el siguiente paso podrás hacer el pago de forma segura',
      'PAYMENT_DETAILS_TPV': 'Pagarás tu pedido con datáfono',
      'PHONE_NOT_GIVEN': 'No has rellenado un teléfono de contacto, que puede ser de ayuda en caso de que el restaurante necesite contactar contigo',
      'PLACE_ORDER': 'Enviar pedido',
      'TITLE': 'Revisa tu pedido'
    },
    'PAYMENT_PAGE': {
      '3DS_CREDIT_CARD_ERROR': 'Ha habido un problema al autorizar el pago con tu banco, por favor vuelve a intentarlo.',
      'ADD_CARD_BUTTON': 'Añadir tarjeta de pago',
      'ADD_COUPON_MESSAGE': 'Introduce tu cupón y el descuento se reflejará en el total de tu pedido.',
      'ADDRESS_HEADING': 'Contacto',
      'ADDRESS_PLACEHOLDER': 'Dirección',
      'APPLY_COUPON_BUTTON': 'Canjear cupón',
      'ASAP_OPTION_LABEL': 'Lo antes posible',
      'CARD_COULD_NOT_BE_DELETED_MESSAGE': 'Ha habido un error al borrar la tarjeta, por favor intenta de nuevo.',
      'CARD_DELETED_MESSAGE': 'La tarjeta ha sido borrada con éxito.',
      'CHANGE_COUPON_BUTTON': 'Cambiar',
      'COMMENTS_HEADING': 'Comentarios',
      'COMMENTS_PLACEHOLDER': 'Comentarios',
      'CORPORATE_DELIVERY_LABEL': 'Corporate delivery - Exclusivo empresas (indicar el cupon de la empresa)',
      'COUPON_HEADING': 'Cupón',
      'COUPON_PLACEHOLDER': 'Introduce el código',
      'CREDIT_DISCOUNT_LABEL': 'Saldo',
      'CREDIT_LEFT_MESSAGE': '(te {isPlural, select, true{quedaran} other{quedara}} {creditLeft})',
      'DELETE_CARD_BUTTON': 'Borrar',
      'DELIVERY_ADDRESS_INVALID': 'No podemos encontrar la dirección que has introducido. Por favor asegurate de introducir solo la calle, número y piso y recuerda que tienes el campo de comentarios para enviar cualquier otra información para el repartidor.',
      'DELIVERY_CHARGE_LABEL': 'Servicio de entrega',
      'DELIVERY_LABEL': 'Delivery',
      'DELIVERY_UNAVAILABLE_LABEL': 'Actualmente el servicio de entrega a domicilio no está disponible. Por favor, elige otra forma de obtener tu pedido o cambia la hora o la zona.',
      'DINING_IN_LABEL': 'Dining in',
      'DISCOUNT_LABEL': 'Descuento',
      'EDIT_CARD_BUTTON': 'Editar',
      'EDIT_ORDER_LABEL_START': 'Puedes',
      'EDIT_ORDER_BUTTON': 'editar tu pedido',
      'EDIT_ORDER_LINK': 'editar pedido',
      'EXPIRED_CREDIT_CARD_ERROR': 'La tarjeta que tienes guardada ha caducado. Por favor añade una nueva tarjeta y vuelve a intentar el pago.',
      'GENERIC_CREDIT_CARD_ERROR': 'No pudimos pagar tu pedido. ¿Podrías revisar los datos de tu tarjeta de pago y volver a intentarlo?',
      'GENERIC_SAVE_ORDER_ERROR': 'No pudimos enviar tu pedido. ¿Podrías volver a intentarlo?',
      'INACTIVE_CREDIT_CARD_ERROR': 'La tarjeta que tienes guardada está inactiva. Por favor añade una nueva tarjeta y vuelve a intentar el pago.',
      'INVALID_COUPON_ERROR': '¡El código es invalido! Comprueba que lo has introducido correctamente y vuelve a intentarlo, por favor.',
      'INVALID_NAME_MESSAGE': 'Introduce tu nombre y apellidos',
      'MINIMUM_ORDER_NOT_MET_MESSAGE': 'El pedido mínimo es de {{minimumOrder}}, sólo te faltan {{difference}} para alcanzarlo.',
      'MISSING_CARD_MESSAGE': '{hasCredit, select, 1{No tienes saldo suficiente para pagar el pedido. ¿Podrías añadir tu tarjeta para que te cobremos el importe que queda?} other{Tu pedido está casi listo, para pagarlo por adelantado necesitamos que añadas una tarjeta de pago.}}',
      'NAME_PLACEHOLDER': 'Nombre y Apellido',
      'NOT_COVERED_DELIVERY_ADDRESS_ERROR_MODAL_MESSAGE': 'No entregamos en la dirección que has introducido. Por favor cambia de dirección o elige otro tipo de pedido (recoger o comer en el local).',
      'NO_CARD_ADDED_MESSAGE': 'Aún no añadiste una tarjeta de pago.',
      'OR_CONFIRM_AND_SEND_ORDER_LABEL': 'o confirmarlo para enviarlo a {{ appName }}.',
      'ORDER_LIMIT_REACHED': 'Lo sentimos, la hora que elegiste ha alcanzado el límite de pedidos. Por favor, edita tu pedido y cambia la hora',
      'PAYMENT_HEADING': 'Pago',
      'PHONE_PLACEHOLDER': 'Teléfono',
      'PREAUTH_GENERIC_ERROR_MESSAGE': 'No pudimos pagar tu pedido. ¿Podrías revisar los datos de tu tarjeta de pago y volver a intentarlo?',
      'REDSYS_WRONG_CVV2_CVC2_ERROR_MESSAGE': 'El código CVV2/CVC2 que introdujiste no es correcto. ¿Podrías volver a intentarlo por favor?',
      'REDSYS_WRONG_EXPIRY_DATE_ERROR_MESSAGE': 'La fecha de caducidad de la tarjeta no está correcta. ¿Podrías volver a intentarlo por favor?',
      'RESTAURANT_CLOSED_LABEL': '@:PAYMENT_PAGE.RESTAURANT_CLOSED_SAVE_ORDER_ERROR',
      'RESTAURANT_CLOSED_SAVE_ORDER_ERROR': 'Nuestras cocinas están cerradas. Puedes volver a intentarlo {openToday, select, false{mañana} other{a las {nextStartTime}}}.',
      'SEND_ORDER_BUTTON': 'Realizar pedido',
      'TAKEOUT_LABEL': 'Lo recoges en el local',
      'TODAY_LABEL': 'Hoy',
      'TOMORROW_LABEL': 'Mañana',
      'TRANSACTION_DENIED_CREDIT_CARD_ERROR': 'Operación denegada. Por favor asegurate que tu tarjeta tiene saldo suficiente y vuelve a intentar el pago!',
      'UNAVAILABLE_LABEL': 'no disponible',
      'UNVERIFIED_ACCOUNT_ERROR': 'Necesitamos verificar tu cuenta para que puedas hacer pedidos.',
      'WHAT_TIME_OPTION_LABEL': '¿A qué hora?',
      'WHERE_HEADING': 'Dónde',
      'YOUR_ORDER_LABEL': 'Tu pedido',
      'ZIP_CODE_PLACEHOLDER': 'Código postal'
    },
    'RESET_PASSWORD_MODAL': {
      'CLOSE_BUTTON_LABEL': 'Cerrar',
      'PASSWORD_CONFIRMATION_LABEL': 'Confirmación contraseña',
      'PASSWORD_LABEL': 'Nueva contraseña',
      'PASSWORD_MIN_LENGTH_HELP_MESSAGE': 'Mínimo 8 caracteres',
      'SAVE_BUTTON_LABEL': 'Guardar',
      'TITLE': 'Cambia tu contraseña',
      'USER_CANCELED_ERROR_MESSAGE': 'Has cancelado el proceso de cambio de contraseña.'
    }
  });
})();
